// Projects data.
// Note: Here we are getting data from a js file, but in a different project I'll be fetching these projects from some srt of APIs.

const projects = [
	{
		id: 1,
		title: 'Pojazd24',
		category: 'Aplikacja mobilna',
		img: require('@/assets/images/project/pojazd24.jpg'),
	}
];

export default projects;
