export const socialLinks = [
	{
		id: 1,
		name: 'GitHub',
		icon: 'github',
		url: 'https://github.com/pojazd24.pl',
	},
	{
		id: 1,
		name: 'Facebook',
		icon: 'facebook',
		url: 'https://www.facebook.com/profile.php?id=61553356656281',
	},
	{
		id: 2,
		name: 'YouTube',
		icon: 'youtube',
		url: 'https://www.youtube.com/@pojazd24',
	},
];
